import React, { useContext } from "react";
import LoadingContext from "../contexts/Loading";
import { Overlay, Spinner } from "evergreen-ui";

export default function Loading(props) {
  const { isLoading } = useContext(LoadingContext);

  return (
    <>
      <Overlay
        isShown={isLoading}
        shouldCloseOnClick={false}
        shouldCloseOnEscapePress={false}
      >
        <Spinner marginX="auto" marginTop="40vh" size={50} />
      </Overlay>
    </>
  );
}
