import { useState } from "react";
import LoadingContext from "./Loading";

export default function LoadingProvider(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {props.children}
    </LoadingContext.Provider>
  );
}
