import "./Home.css";
import { Button, Dialog, Heading, Pane, TextInput } from "evergreen-ui";
import bg from "../../assets/bg.jpg";
import hanger from "../../assets/hanger.jpg";

import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/navigation";

import { EffectCards } from "swiper/modules";

import slide1 from "../../assets/slide1.png";
import slide2 from "../../assets/slide2.png";
import slide3 from "../../assets/slide3.png";
import slide4 from "../../assets/slide4.png";
import slide5 from "../../assets/slide5.png";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { useContext, useEffect, useState } from "react";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../utils/firebase";
import UserContext from "../../contexts/User";
import toast from "react-hot-toast"
function Home() {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')


  const { authed } = useContext(UserContext);
  useEffect(() => {
    if (authed) {
      navigate("/catalog");
    }
  }, [authed, navigate]);
  const handleSubmit = async () => {
    await setDoc(doc(db, "waitlist", email.toLowerCase()), {name: name, email: email.toLowerCase(), submittedAt: new Date(), processed: false})
    setShowDialog(false)
    toast.success("Joined waiting list")
  }

  return (
    <div className="App" style={{ marginTop: 20, textAlign: 'left', width: '80vw', marginLeft: 'auto', marginRight: 'auto' }}>
      <Dialog
      isShown={showDialog}
      title={"Join Waitlist"}
      confirmLabel="Join"
      hasFooter={false}
      onCloseComplete={() => setShowDialog(false)}
      >
        <TextInput  width={"100%"} placeholder="Name" style={{marginBottom: 10, fontFamily: "DM Sans"}} onChange={e => setName(e.target.value)}/>
        <br/>

        <TextInput  width={"100%"} placeholder="Email" type="email" style={{marginBottom: 10, fontFamily: "DM Sans"}} onChange={e => setEmail(e.target.value)}/>
        <br/>
        <Button style={{marginBottom: 10, float:'right', fontFamily: "DM Sans"}}
        onClick={handleSubmit}
        >Join</Button>
      </Dialog>
     <h1 style={{fontSize: 100, marginBottom: 0}}>FIND YOUR FIT</h1>
     <h1 style={{color: "#858585", fontSize: 100, marginTop: 0, marginBottom: 0}}>FIND YOUR FEEL</h1>
     <p style={{fontFamily: "DM Sans"}}>Discover your perfect look</p>
     <p style={{fontFamily: "DM Sans"}}>Try on clothes virtually</p>
     <Button style={{width: 300, fontSize: 20, color: 'white', backgroundColor: "#555555", height: 50, fontFamily: "DM Sans"}}
     onClick={() => setShowDialog(true)} 
     >Join Waitlist</Button>
    </div>
  );
}

export default Home;
