import { Pane } from "evergreen-ui";
import { useContext, useEffect } from "react";
import UserContext from "../../contexts/User";
import { useNavigate } from "react-router-dom";

export default function Processing(props) {
    const { user } = useContext(UserContext)
    const navigate = useNavigate()

    useEffect(() => {
        if (user.uid && user.processed) {
            navigate("/catalog")
        }
    }, [user])
    return (<Pane style={{textAlign: "center", marginTop: "30vh", fontFamily: 'DM Sans'}}>Please hold, we are getting you set up! We'll send you an email when we're done - keep an eye on your mailbox!</Pane>)
}