import { auth, storage, db } from "./firebase";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  getIdToken,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  query,
  getDocs,
  collection,
  where,
  doc,
  setDoc,
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const googleProvider = new GoogleAuthProvider();

export async function handleUpload(file, path, fileId) {
  if (!file) {
    alert("Please choose a file first!");
  }

  const storageRef = ref(storage, `/${path}/${fileId}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  await uploadTask;
  return await getDownloadURL(uploadTask.snapshot.ref);
}

export async function getFileUrl(path) {
  const storageRef = ref(storage, `/${path}`);
  return await getDownloadURL(storageRef);
}

const fetchHelper = async (path, type, body = null, setIsLoading) => {
  const options = {
    method: type,
    headers: {
      "Content-Type": "application/json",
    },
  };
  let authToken = null;
  try {
    authToken = await getIdToken(auth.currentUser);
  } catch (error) {
    authToken = null;
  }
  if (authToken) {
    options.headers.Authorization = `Bearer ${authToken}`;
  }
  if (body) {
    options.body = JSON.stringify(body);
  }
  if (setIsLoading) {
    setIsLoading(true);
  }
  const URL = process.env.REACT_APP_BACKEND_URL;
  return fetch(`${URL}${path}`, options).then((response) => {
    if (setIsLoading) {
      setIsLoading(false);
    }
    if (!response.ok) {
      return Promise.reject(response);
    }
    return response.json();
  });
};

export const fetchGet = async (path, setIsLoading) => {
  return fetchHelper(path, "GET", null, setIsLoading);
};

export const fetchPost = async (path, body, setIsLoading) => {
  return fetchHelper(path, "POST", body, setIsLoading);
};

export const fetchPut = async (path, body, setIsLoading) => {
  return fetchHelper(path, "PUT", body, setIsLoading);
};

export const fetchDelete = async (path, body, setIsLoading) => {
  return fetchHelper(path, "DELETE", body, setIsLoading);
};

export function idGenerator() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
}

export async function createUser(email, pw) {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, pw);

    const user = res.user || res;
    console.log("user", user)
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      authProvider: "none",
      email,
    });
    return user;
  } catch (err) {
    if(err.code == 'auth/email-already-in-use'){
      alert("This email is already in use. If you already have an account, press 'Click here to login'")
    }
    else{
      console.error(err);
      alert(err.message);  
    }
  }
}

export function verifyEmail(user) {
  return sendEmailVerification(user);
}

export async function logout() {
  return signOut(auth).then(() => {
    window.location.href = "/";
  });
}

export async function login(email, pw) {
  try {
    console.log("login");
    const user = await signInWithEmailAndPassword(auth, email, pw);
    return user;
  } catch (err) {
    if (err.code === 'auth/user-not-found') {
      alert("Sorry, there's no user with this email! If you are new, press 'Click here to register' to make an account.");
    }
    else if (err.code === 'auth/wrong-password') {
      alert('Incorrect password, please try again');
    }
    else{
      console.error(err);
      alert(err.message);
    }
  }
}

export async function signInWithGoogle() {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        type: "user",
      });
    }

    return user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
}

export async function resetPassword(email) {
  return sendPasswordResetEmail(auth, email);
}

export function validateEmail(email) {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
}

export async function featureFlagEmailVerification() {
  let result = await fetchGet("/feature_flag");
  result = result.filter((res) => res.Key === "EMAIL_VERIFICATION");
  if (result.length > 0) {
    return result[0].Value === "Y";
  }
  return false;
}

export async function isCurrentUserEmailVerified() {
  await auth.currentUser.reload();
  const user = auth.currentUser;
  return user.emailVerified;
}

export function getCurrentUser() {
  return auth.currentUser;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}