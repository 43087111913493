import {
  Button,
  Card,
  FileCard,
  FileUploader,
  Heading,
  Pane,
  Paragraph,
  SideSheet,
  Tab,
  Tablist,
} from "evergreen-ui";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingContext from "../../contexts/Loading";
import UserContext from "../../contexts/User";
import { handleUpload, fetchPost, idGenerator } from "../../utils/helpers";
import "./Admin.css";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../utils/firebase";

export default function Admin(props) {
  const { authed } = props;
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [fileRejections, setFileRejections] = useState([]);
  const handleChange = useCallback((files) => setFiles([files[0]]), []);
  const handleRejected = useCallback(
    (fileRejections) => setFileRejections([fileRejections[0]]),
    []
  );
  const handleRemove = useCallback(() => {
    setFiles([]);
    setFileRejections([]);
  }, []);
  const { setIsLoading } = useContext(LoadingContext);
  const [displayedPoints, setDisplayedPoints] = useState({})
  const [currentPoint, setCurrentPoint] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [allPoints, setAllPoints] = useState([])
  const imageRef = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = ["Products", "User Templates"];
  const [realPoints, setRealPoints] = useState({});
  const [imageName, setImageName] = useState("");
  const [products, setProducts] = useState([]);
  const [currProduct, setCurrProduct] = useState(0);
  const [currImage, setCurrImage] = useState(0);
  const [productDocRef, setProductDocRef] = useState();

  const imageClick = (e) => {
    const offset = imageRef.current.getBoundingClientRect();

    const x = e.pageX;
    const y = e.pageY;
    console.log("click", x, y);
    let newPoints = [...(displayedPoints[currentPoint.name] === undefined ? [] : displayedPoints[currentPoint.name])];
    newPoints.push([x, y]);
    newPoints = newPoints.slice(-2);
    setDisplayedPoints(prev => ({...prev, [currentPoint.name]: [...newPoints]}))
    const distanceToTop = window.pageYOffset + offset.top;

    setRealPoints({
      ...realPoints,
      [currentPoint.name]: newPoints.map((point) => {
        const realX = (point[0] - offset.left) / offset.width;
        const realY = (point[1] - distanceToTop) / offset.height;
        return { x: realX, y: realY };
      }),
    });
  };

  useEffect(() => {
    const fetchPoints = async () => {
      const productsRef = collection(db, "points");
      const q = query(productsRef, where("visible", "==", true));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.length > 0) {
        const data = querySnapshot.docs.map((doc) => doc.data());
        data.sort((a, b) => a.order - b.order)
        console.log("points",data)
        setAllPoints(data);
        setCurrentPoint(data[0])
      }
    }
    fetchPoints()
  }, [])

  const dotWidth = 25;
  const dotHeight = 25;
  const isProductTab = selectedIndex === 0;
  const fetchProducts = async () => {
    const productsRef = collection(db, "catalog");
    const q = query(productsRef, where("traced", "!=", true), limit(100));
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot.docs);
    if (querySnapshot.docs.length > 0) {
      setProducts(querySnapshot.docs.map((doc) => doc.data()));
    } else {
      alert("NO PRODUCTS TO BE TRACED")
    }
  };

  const fetchTemplates = async () => {
    const productsRef = collection(db, "templates");
    const q = query(
      productsRef,
      where("traced", "!=", true),
      orderBy("traced"),
      orderBy("submittedAt", "desc"),
      limit(100)
    );
    const querySnapshot = await getDocs(q);
    console.log(querySnapshot.docs);
    if (querySnapshot.docs.length > 0) {
      setProducts(querySnapshot.docs.map((doc) => doc.data()));
    } else {
      alert("NO TEMPLATES TO BE TRACED")

    }
  };

  useEffect(() => {
    if (selectedIndex === 0) {
      fetchProducts();
    } else if (selectedIndex === 1) {
      fetchTemplates();
    }
    setCurrProduct(0);
    setCurrImage(0);
  }, [selectedIndex]);

  useEffect(() => {
    if (displayedPoints[currentPoint.name] === undefined) {
      return;
    }
    displayedPoints[currentPoint.name].forEach((point, index) => {
      const div = document.getElementById(`${currentPoint.name}-${index}`);
      console.log("clicked", currentPoint)
      console.log(div);
      div.style.position = "absolute";
      div.style.display = "block";
      const divX = point[0] - dotWidth / 2;
      const divY = point[1] - dotHeight / 2;
      div.style.left = divX + "px";
      div.style.top = divY + "px";
      div.style.zIndex = 100000;
    });
  }, [displayedPoints, currentPoint]);

  // TODO: fix this to display previously generated template
  const revertRealPointToDisplayed = (point) => {
    const { x, y } = point;
    const offset = imageRef.current.getBoundingClientRect();
    const distanceToTop = window.pageYOffset + offset.top;

    const displayX = x * offset.width + offset.left;
    const displayY = y * offset.height + distanceToTop;
    console.log("{x: displayX, y: displayY }", { x: displayX, y: displayY });
    return { x: displayX, y: displayY };
  };
  useEffect(() => {
    if (selectedIndex === 0) {
      if (currProduct >= products.length) {
        if (products.length === 0) {
          return;
        }
        setCurrProduct(0);
      } else if (
        products.length > 0 &&
        products[currProduct].images !== undefined
      ) {
        console.log("products", products, currProduct);
        setImageUrl(products[currProduct].images[currImage]);
      }
    } else {
      console.log("products[currProduct]", products[currProduct]);
      if (products[currProduct] !== undefined) {
        setImageUrl(products[currProduct].url);
      }
    }

    setCurrentPoint(allPoints[0]);
    setDisplayedPoints({})
    setRealPoints({});
    const dots = document.getElementsByClassName("dot");
    [...dots].forEach((dot) => {
      dot.style.display = "none";
    });
  }, [products, currImage, currProduct, selectedIndex, allPoints]);

  useEffect(() => {
    setCurrImage(0);
  }, [currProduct]);
  console.log("currproduct", currProduct, products[currProduct])

  const submit = async () => {
    const hasAllData = allPoints.every(point => realPoints[point.name].length === point.numPoints)

    if (
      !hasAllData
    ) {
      alert("Missing point data");
      return;
    } else {
      try {
        if (selectedIndex === 0) {
          const data = {
            maskImage: products[currProduct].images[currImage],
            traced: true,
            processed: false
          };
          for (let i = 0; i < allPoints.length; i++) {
            const point = allPoints[i]
            data[point.name] = realPoints[point.name]
          }
          await updateDoc(doc(db, "catalog", products[currProduct].id), {
            ...data,
            updatedAt: new Date(),
          });
        }
        else {
          const data = {
            traced: true,
            processed: false,
          };
          for (let i = 0; i < allPoints.length; i++) {
            const point = allPoints[i]
            data[point.name] = realPoints[point.name]
          }
          await updateDoc(doc(db, "templates", products[currProduct].name), {
            ...data,
            updatedAt: new Date(),
          });
          console.log('batch generating products for template: ', products[currProduct].name);
          fetchPost(
            `/initial_batch_product_generation/template/${products[currProduct].name}`,
          );
        }

        alert("Submitted");
        setCurrProduct((currProduct) => currProduct + 1);
      } catch (err) {
        alert(err);
        alert("Failed to upload data points");
      }
    }
  };
  const deleteProduct = async () => {
    try {
      await deleteDoc(doc(db, "catalog", products[currProduct].id));
    } catch (err) {
      alert("Failed to delete");
      console.log(err);
    }
    alert("Successfully deleted!");
    window.location.reload();
  };

  let body = null;
  if (!authed) {
    return;
  }

  let tabBody;
  let heading = "";
  heading = <>{currentPoint.heading}</>;
  console.log("allpoints", allPoints)

  body = (
    <>
     {allPoints.map(point => {
        return [...Array(point.numPoints).keys()].map(index => <div key={`${point.name}-${index}`} class="dot" id={`${point.name}-${index}`}></div>)

      })}
      <></>
      {isProductTab && (
        <Button intent="danger" appearance="primary" onClick={deleteProduct}>
          Delete Product
        </Button>
      )}
      <Button
        onClick={() => setCurrProduct((prev) => prev + 1)}
        style={{ float: "right" }}
      >
        Next
      </Button>

      {currProduct > 0 && (
        <Button
          onClick={() => setCurrProduct((prev) => prev - 1)}
          style={{ float: "right" }}
        >
          Back
        </Button>
      )}
      <Paragraph>{heading}</Paragraph>

      {isProductTab &&
        products[currProduct] !== undefined &&
        products[currProduct].images !== undefined &&
        products[currProduct].images.map((image, index) => {
          const active = index === currImage;
          return (
            <>
              <img
                src={image}
                onClick={(e) => setCurrImage(index)}
                style={{
                  height: 100,
                  ...(active && { border: "1px red solid" }),
                }}
              />
            </>
          );
        })}
      <div style={{display: "flex", overflowX: "auto"}}>
        {!isProductTab &&
          products !== undefined &&
          products.map((product, index) => {
            const active = index === currProduct;
            return (
              <>
                <img
                  src={product["url"]}
                  onClick={(e) => setCurrProduct(index)}
                  style={{
                    height: 100,
                    ...(active && { border: "1px red solid" }),
                  }}
                />
              </>
            );
        })}
      </div>

      <img
        ref={imageRef}
        onClick={imageClick}
        alt="file upload"
        src={imageUrl}
        style={{ width: "100%" }}
      />
      <Button
        appearance="primary"
        onClick={() => {
          const index = allPoints.findIndex(point => point.name === currentPoint.name)
            if (index === allPoints.length - 1) {
              submit()
              return
            } else {
              setCurrentPoint(allPoints[index + 1])
            }
        }}
        style={{ float: "right", marginBottom: 15 }}
        disabled={
          displayedPoints[currentPoint.name] === undefined || displayedPoints[currentPoint.name] < currentPoint.numPoints
        }
      >
        Confirm
      </Button>
    </>
  );

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        minHeight: "100vh",
        background:
          "linear-gradient(51deg, rgba(206,204,247,1) 0%, rgba(255,255,255,1) 100%)",
      }}
    >
      <div style={{ paddingTop: 100 }}>
        <Pane
          maxWidth={"90vw"}
          width={800}
          background="tint2"
          border="muted"
          elevation={1}
          padding={40}
          style={{ margin: "0 auto" }}
        >
          <Tablist
            flexBasis={240}
            style={{ margin: "0 auto", textAlign: "center" }}
          >
            {tabs.map((tab, index) => (
              <Tab
                aria-controls={`panel-${tab}`}
                isSelected={index === selectedIndex}
                key={tab}
                onSelect={() => setSelectedIndex(index)}
              >
                {tab}
              </Tab>
            ))}
          </Tablist>
          {body}
        </Pane>
      </div>
    </div>
  );
}
