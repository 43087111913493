import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./containers/Home/Home";
import Login from "./containers/Login/Login";
import Register from "./containers/Login/Register";
import { getAuth } from "firebase/auth";
import LoadingProvider from "./contexts/LoadingProvider";
import UserProvider from "./contexts/UserProvider";
import Setup from "./containers/Setup/Setup";
import Loading from "./components/Loading";
import Catalog from "./containers/Catalog/Catalog";
import Showroom from "./containers/Showroom/Showroom";
import { Toaster } from 'react-hot-toast';
import Product from "./containers/Catalog/Product";
import ProductMobile from "./containers/Catalog/ProductMobile";
import Admin from "./containers/Admin/Admin";
import { ThemeProvider, defaultTheme } from 'evergreen-ui';
import { merge } from 'lodash';
import "./App.css"
import Processing from "./containers/Processing/Processing";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./utils/firebase";
import { isMobile } from "react-device-detect";

function ProtectedRoute(props) {
  const { authed, user } = props
  const [userInfo, setUserInfo] = useState({})
  const updateInfo = async () => {
    const querySnapshot = await getDoc(doc(db, "users", user.uid));
    if (querySnapshot.exists()) {
      setUserInfo(querySnapshot.data())
    }
  }
  useEffect(() => {
    updateInfo()
  }, [user])
  if (!authed || user === undefined || !user.uid) {
    return null;
  }
  console.log("protected", userInfo)
  if (userInfo.processed === false && userInfo.type === "user") {
    return <Processing />
  }
  return props.children
}
export default function App() {
  const [authed, setAuthed] = useState(false);
  const [email, setEmail] = useState();
  const [user, setUser] = useState({});
  useEffect(() => {
    const removeListener = getAuth().onAuthStateChanged((user) => {
      console.log("auth state changed", user);
      if (user) {
        setAuthed(true);
        setEmail(user.email);
        setUser(user);
      } else {
        setAuthed(false);
      }
    });

    return () => removeListener();
  }, []);

  const myTheme = merge({}, defaultTheme, {
    fontFamilies: {
        display: '"Anton", sans-serif',
        ui: '"Anton", sans-serif',
        mono: '"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace'
    },
    components: {
      Button: {
        appearances: {
          primary: {
            backgroundColor: "black",
            fontSize: 18,
            color: 'white'
          },
          minimal: {
            fontSize: 18
          }
        }
      }
    }
});

  return (
    <ThemeProvider value={myTheme}>
    <LoadingProvider>
      <Toaster
      position="top-center"
      reverseOrder={false}
      containerStyle={{
        fontFamily: 'DM Sans',
        top: "60%",
        left: "50%",
        transform: "translate(-50%, -50%)"
      }}
      />
      <UserProvider email={email} authed={authed} user={user}>
        <BrowserRouter>
          <Loading />
          <NavBar authed={authed} />
          <Routes>
            {/* <Route path="/" element={<Home />} /> */}
            <Route path="/login" element={<Login authed={authed} />} />
            <Route path="/processing" element={<Processing />} />
            <Route path="/register" element={<Register authed={authed} />} />
            <Route path="/setup" element={<ProtectedRoute authed={authed} user={user}><Setup authed={authed} /></ProtectedRoute>} />
            <Route path="/catalog" element={<ProtectedRoute authed={authed} user={user}><Catalog authed={authed} /></ProtectedRoute>} />
            <Route path="/fitting" element={<ProtectedRoute authed={authed} user={user}>{isMobile ? <ProductMobile authed={authed} /> : <Product authed={authed} />}</ProtectedRoute>} />
            <Route path="/admin" element={<ProtectedRoute authed={authed} user={user}><Admin authed={authed} /></ProtectedRoute>} />
            <Route path="*" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </UserProvider>
    </LoadingProvider>
    </ThemeProvider>
  );
}
