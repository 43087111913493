import { useContext, useEffect, useState } from "react";
import { fetchGet, logout } from "../utils/helpers";
import UserContext from "./User";
import LoadingContext from "./Loading";
import { db } from "../utils/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";

export default function UserProvider(props) {
  const [user, setUser] = useState({});
  const [authed, setAuthed] = useState(false);
  const { setIsLoading } = useContext(LoadingContext);

  const updateUser = async () => {
    const q = query(
      collection(db, "users"),
      where("uid", "==", props.user.uid)
    );
    const docs = await getDocs(q);
    if (docs.docs.length > 0) {
      const templatesQuery = query(
        collection(db, "templates"),
        where("user", "==", props.user.uid)
      );
      console.log("props.user.uid", props.user.uid);
      const templateDocs = await getDocs(templatesQuery);
      setUser({
        ...props.user,
        ...docs.docs[0].data(),
        isSetUp: templateDocs.docs.length > 0,
        originalDBData: {...docs.docs[0].data()}
      });
      setAuthed(true);
    } else {
      setUser({
        ...props.user,
        isSetUp: false,
        originalDBData: {}
      });
    }
  };

  useEffect(() => {
    console.log("authed", props.authed)
    if (props.authed) {
      updateUser();
      setAuthed(true);
    } else {
      setUser({});
      setAuthed(false);
    }
  }, [props.authed, props.email, props.user, setIsLoading]);

  console.log("user", user);
  return (
    <UserContext.Provider value={{ authed, user, updateUser }}>
      {props.children}
    </UserContext.Provider>
  );
}
